@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module HeaderExtraBlock.module.scss
 *  @since 2023.02.02, 07:34
 *  @changed 2023.02.02, 07:34
 */

.container {
  display: flex;
  gap: 5px;
}
