@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module PageHeader.module.scss
 *  @since 2023.01.27, 16:20
 *  @changed 2023.02.02, 02:05
 */

.isRoot {
  /*!KEEP*/
  // TODO?
}

.container {
  background-color: $primaryColor;
}

.box {
  margin: 0 $blockPaddingPx;
}

.logoBox {
  width: 130px; // Logo size by width
  height: 100px; // Determine entire header height (+ 2 * {box-v-padding})
  background-image: url('/images/assets/Logo_White.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity $transitionTimeMs;
}

.searchBox {
  flex: 1; // Full width
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}
