@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module PageFooter.module.scss
 *  @since 2023.01.27, 16:20
 *  @changed 2023.01.27, 16:20
 */

.container {
  background-color: $primaryColor;
  height: 240px;
}
