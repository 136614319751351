@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module PageContent.module.scss
 *  @since 2023.01.27, 16:20
 *  @changed 2023.01.27, 16:20
 */

.container {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;
}
