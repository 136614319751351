@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module ArticleView.module.scss
 *  @since 2023.01.29, 22:45
 *  @changed 2023.02.01, 01:14
 */

.container {
  margin: $blockPaddingPx;
}

.errorSection {
  margin: $blockPaddingPx;
  margin-bottom: 0;
}

.contentLayout {
  display: flex;
  flex-direction: column;
  gap: $containerPaddingPx;
}

// Layout geometry...

.thumbnailRow {
  background-color: $primaryColor;
  background-color: $neutralColors100;
  padding: 15px;
}
.thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.column {
  display: flex;
  flex-direction: column;
  gap: $containerPaddingPx;
}
.row {
  margin: 0;
  gap: $containerPaddingPx;
}

@media (min-width: $breakpointsSmPx) {
  // .contentLayout {
  // }
  .thumbnail {
    height: 300px;
  }
}
@media (min-width: $breakpointsMdPx) {
  .contentLayout {
    display: flex;
    flex-direction: row-reverse;
  }
  .mainColumn {
    flex: 1;
  }
  .mediaColumn {
    width: 35%;
  }
  .thumbnail {
    // height: 350px;
  }
}

// Theming...

.bodyRow {
  position: relative;
}

.titleRow,
.headlineRow {
  font-family: $titleFont;
}
.titleRow {
  font-size: 34px;
  font-weight: bold;
}
.headlineRow {
  font-size: 20px;
}

.bookmarksRow {
  opacity: 0.25;
}
.dateRow {
  font-size: $fontSizeSmPx;
  opacity: 0.3;
  font-weight: bold;
  text-transform: uppercase;
}
